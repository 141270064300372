import { useMediaQuery } from 'react-responsive';
export const fontSizes = [
  ['Small', '15px'],
  ['Medium', '18px'],
  ['Large', '21px'],
];

export const mobileCutoff = '550px';
export const useIsMobile = () => {
  return useMediaQuery({
    query: `(max-width: ${mobileCutoff})`,
  });
};

export const DefaultFont = 'Inter, sans-serif';
export const DarkenSlightly = '#6e6e6e13';
export const DarkenMedium = '#4141413e';

export type Theme = {
  displayName: string;
  colors: {
    main: {
      light: string;
      medium: string;
      dark: string;
    };
    link: {
      background: string;
      text: string;
    };
    highlight: string;
  };
  backgroundColor?: string;
  scrollbarColor?: string;
  scrollbarThumbColor?: string;

  fontSize?: number;
  headerFont?: string;
  defaultFont?: string;
  editorFont?: string;
};

interface ThemeMap {
  [key: string]: Theme;
}

export const colors = {
  offBlack: '#414141',
  lightOffBlack: '#494949',
};

export const themes: ThemeMap = {
  DEFAULT_THEME: {
    displayName: 'Default',
    backgroundColor: '#EDE1CC',
    scrollbarColor: '#d3c7b4',
    scrollbarThumbColor: '#bbb1a1',
    colors: {
      main: {
        light: '#cabfad',
        medium: '#aa9f8c',
        dark: '#857c6d',
      },
      link: {
        background: '#cde4fe',
        text: '#0366d6',
      },
      highlight: '#ACCEF7',
    },
    headerFont: 'Inter, sans-serif',
    editorFont: 'Inter, sans-serif',
  },
  // YANA_THEME: {
  //   displayName: 'YANA',
  //   colors: {
  //     main: {
  //       light: '#ffcccc',
  //       medium: '#ff8c8c',
  //       dark: '#ff0000',
  //     },
  //     link: {
  //       background: '#ffcccc',
  //       text: '#8a0000',
  //     },
  //     highlight: '#ff8c8c',
  //   },
  // },
  // OLD_EXEGESIS: {
  //   displayName: 'Classic exegesis',
  //   colors: {
  //     main: {
  //       light: 'rgb(219, 219, 219)',
  //       medium: 'rgb(196, 196, 196)',
  //       dark: '#52616b',
  //     },
  //     link: {
  //       background: '#cde4fe',
  //       text: '#0366d6',
  //     },
  //     highlight: '#ACCEF7',
  //   },
  // },
  // SOLARPUNK_THEME:{
  //     displayName:"Solarpunk",
  //     colors: {
  //         main:{
  //             light:"#fad5ad",
  //             medium: "#bea284",
  //             dark: "#8b765e",
  //         },
  //         link:{
  //             background:"#ff9a8c",
  //             text:"#8f554d",
  //         },
  //         highlight:"#ff8c8c",
  //     },
  //     backgroundColor:"#adb36e"
  // }
};
