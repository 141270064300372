import Link from 'next/link';
import { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '..';

export const AuthorizationWrapper = (props: { children: any }) => {
  const user = useContext(AuthContext);
  if (!user || user.loggedInUser === null) {
    return (
      <UnauthorizedContainer>
        <h1>you need to be logged in</h1>
        <Link href="/login">
          <LoginText>login</LoginText>
        </Link>
        <br />
      </UnauthorizedContainer>
    );
  } else return <>{props.children}</>;
};

const LoginText = styled.button`
  font-size: 20px;
  margin-top: 10px;
  padding: 5px;
  background: none;
  border: 1px solid black;
  :hover {
    background-color: grey;
  }
`;

const UnauthorizedContainer = styled.div`
  padding: 5vw;
`;
