import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from 'firebase/auth';
import { deleteObject, getStorage, listAll, ref } from 'firebase/storage';
import React from 'react';

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);
export const auth = getAuth();

export function deleteFolderContents(path: string) {
  const curr = ref(getStorage(), path);
  console.log('CURR', curr);
  listAll(curr)
    .then((dir) => {
      console.log('DIR', dir);
      dir.items.forEach((fileRef) => {
        console.log('fileRef', fileRef);
        deleteFile(curr.fullPath, fileRef.name);
      });
      dir.prefixes.forEach((folderRef) => {
        deleteFolderContents(folderRef.fullPath);
      });
    })
    .catch((error) => {});
}

export function deleteFile(pathToFile: string, fileName: string) {
  const curr = ref(getStorage(), pathToFile + '/' + fileName);
  deleteObject(curr);
}

const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
export const signInWithGoogle = async () => {
  return signInWithPopup(auth, googleProvider);
};
export const signInWithTwitter = async () => {
  return signInWithPopup(auth, twitterProvider);
};

export const AuthContext = React.createContext(
  null as { loggedInUser: any; authLoading: boolean } | null
);
